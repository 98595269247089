import {pageInfo} from "./page_info.js";

function initPixel() {
    const pixelData = pageInfo.facebook_pixel_data;
    if (!pixelData) {
        return;
    }
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) {
            return;
        }
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) {
            f._fbq = n;
        }
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', `${pixelData.id}`);
    fbq('track', 'PageView');

    function triggerFbPixelEvents(events) {
        events.forEach((event, _) => {
            let data = null;
            try {
                data = event['data'];
            } catch (e) {
            }
            fbq('track', event['name'], data);
        });
    }

    $(document).on('basket_add_form_submitted', (event, data) => {
        triggerFbPixelEvents(data.fbp_events)
    });

    try {
        const eventsElement = document.getElementById('fbp-events');
        if (!eventsElement) {
            return;
        }
        const events = JSON.parse(eventsElement.textContent);
        triggerFbPixelEvents(events);
    } catch (e) {
        console.log(e);
    }
}

$(initPixel);