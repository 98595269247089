"use strict";

$(function() {
    var filter_box = $('#filters'),
        filter_button = $('.js-filters-btn'),
        filter_button_original_label = filter_button.text(),
        active_filters = filter_box.find('.filter-item-active'),
        filters_summary = $('#filters-summary'),
        make_label = function(group, name, undo) {
            if (!(group && name)) {
                return '';
            }
            var bits = ['<li class="filter-item-summary">'];

            if (undo) {
                bits = bits.concat('<a class="filter-item-undo" href="', undo ,'">');
            }

            bits = bits.concat('<span class="badge badge-pill badge-primary">', group, ': ', name);

            if (undo) {
                bits = bits.concat('<span class="btn-filter-item-undo">&times;</span></a>');
            }

            bits = bits.concat('</span>', '</li>');
            return bits.join('');
        },
        summarize_filters = function() {
            // Find all the active filters
            var summary_el = filters_summary,
                summary_bits = [];
            // Render active filters into labels
            for(var i=0, len=active_filters.length; i < len; i++) {
                var f = $(active_filters[i]),
                    f_group = f.data('filtersGroup'),
                    f_name = f.data('filtersName'),
                    f_undo = f.data('filtersUndo');
                summary_bits.push(make_label(f_group, f_name, f_undo));
            }
            // Add summary to DOM
            summary_el.html(summary_bits.join(''));
        };

    // If there are active filters and the summary box is visible, populate it
    if (active_filters.length > 0 && filters_summary.is(':visible')) {
        summarize_filters();
    }

    // Change the label of the filter toggle button when the filters are expanded
    $('#filters').on('show.bs.collapse', function () {
          filter_button.text(filter_button.data('expanded-label')).addClass('btn-outline-primary').removeClass('btn-primary');
    }).on('hide.bs.collapse', function(){
        filter_button.text(filter_button_original_label).addClass('btn-primary').removeClass('btn-outline-primary');
    });

    // Bind events to facet checkboxes
    $('.filter-checkbox').on('change', function() {
        window.location.href = $(this).next('.filter-url').val();
    });

    // Auto-submit (hidden) search form when selecting a new sort-by option
    $('#id_sort_by, #id_items_per_page').on('change', function() {
        $(this).closest('form').submit();
    });
});
