"use strict";

import Cookies from "js-cookie";

$(function() {
    const csrf_param_name = 'csrfmiddlewaretoken',
        csrf_cookie = 'csrftoken';

    // We want to make sure the CSRF value is updated on submit
    // For issues caused by login/logout on another tab
    $('form').on('submit', function(e) {
        const csrf_token = Cookies.get(csrf_cookie);
        $(this).find('input[name='+csrf_param_name+']').val(csrf_token);
    });

    // For ajax forms use a header to provide the CSRF token
    $.ajaxPrefilter(function(options) {
        if (!options.beforeSend) {
            options.beforeSend = function (xhr, settings) {
                // jQuery silliness - see https://bugs.jquery.com/ticket/12004
                const request_method = settings.type || settings.method;
                // For unsafe requests only, mess around with CSRF
                // Note - inArray returns an array index, so falsy checks will fail.
                if($.inArray(request_method, ['GET', 'HEAD']) === -1) {
                    const csrf_token = Cookies.get(csrf_cookie);
                    // Remove csrf from data if it is there
                    // Otherwise the mismatching values will raise an error
                    if (settings.data){
                        let params = settings.data.split('&');
                        settings.data = params.filter(function(param) {
                            return param.substr(0, csrf_param_name.length) !== csrf_param_name;
                        }).join('&');
                    }
                    xhr.setRequestHeader('X-CSRFToken', csrf_token);
                }
            }
        }
    });
});
