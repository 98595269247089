"use strict";

import "devbridge-autocomplete";

$(function(){

    var defaultFormatResults = $.Autocomplete.defaults.formatResult;

    // Handling of category filter in search bar, when present
    $('#search-category-select').on('click', 'a', function(e) {
        e.preventDefault();
        var $el = $(this);
        $(e.delegateTarget)
            .find('.dropdown-select-input')
            .val($el.data('value'))
            .end()
            .find('.dropdown-select-label')
            .text($el.text());
    });

    $('.typeahead').autocomplete({
        groupBy: 'group',
        serviceUrl: function(){
            var queryData = {};
            var cat_el = $('.dropdown-select-input');
            if(cat_el) {
                queryData['category'] = cat_el.val();
            }
            return '/catalogue/search/suggest/?' + $.param(queryData);
        },
        triggerSelectOnValidInput: false,
        paramName: 'q',
        minChars: 2,
        maxHeight: 450,
        deferRequestBy: 300,
        onSelect: function (suggestion){
            window.location.href = suggestion.data.url;
        },
        formatResult: function (suggestion, currentValue) {
            var result = defaultFormatResults(suggestion, currentValue);
            if(suggestion.data.image_url){
                result = '<img class="product-suggestion-img d-none d-sm-inline" src="' + suggestion.data.image_url + '" /> ' + result;
            }
            return result;
        },
        transformResult: function(response, originalQuery){
            var parsedResponse = jQuery.parseJSON(response);
            var search_suggestions = jQuery.map(parsedResponse.search_suggestions, function(item) {
                return {
                    value: item,
                    data: {
                        'url': '/search/?q=' + encodeURIComponent(item)
                    },
                };
            });

            if(parsedResponse.product_suggestions){
                var product_suggestions = jQuery.map(parsedResponse.product_suggestions.results, function(item){
                    return {
                        value: item.title,
                        data: {
                            'group': 'Top products<span class="d-none d-sm-inline"> matching your search</span>',
                            'image_url': item.image_url,
                            'url': item.url
                        },
                    };
                });

                search_suggestions = search_suggestions.concat(product_suggestions);
            }

            return {
                suggestions: search_suggestions
            }
        }
    });
});
