"use strict";

$(function() {
    if (window.BroadcastChannel) {
        var channel = new BroadcastChannel('oscar');

        // Listen for any broadcast events and send them to other tabs
        $(document).on('broadcast', function(e, data) {
            channel.postMessage(data);
        });

        // Listen for incoming messages and trigger the event in this tab
        channel.onmessage = function (e) {
            var payload = e.data,
                event = payload.event;
            if (event) {
                $(document).trigger(event.type, event.data || {});
            }
        };

        // Listen to reload-required events being broadcast by other tabs
        // These are triggered when the user logs in or out
        $(document).on('reload-required', function(e) {
            // Do nothing for the moment
        });
    }
});
