"use strict";

import "bootstrap/js/dist/tooltip";

$(function() {
    // We use a class rather than an attribute selector for performance reasons
    var $tooltips = $('.page-tooltip');
    // Check if we have any elements before attempting to register tooltip.
    // See https://github.com/twbs/bootstrap/issues/29474
    if($tooltips.length) {
        $tooltips.tooltip();
    }
});
