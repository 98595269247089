"use strict";

import "bootstrap/js/dist/carousel";

$(function() {
    if (window.gtag) {
        $('.carousel-item').on('click', 'a', function(e){
            var a = $(this);
            window.gtag('event', 'click', {
                'event_category': 'carousel',
                'event_label': a.closest('.carousel-item').index() + ' - ' + a.attr('href')
            });
        });
    }
});
