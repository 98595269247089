import {pageInfo} from "./page_info.js";

var recaptchaSiteKey = pageInfo.recaptcha_site_key;
var recaptchaLoaded = false;
var loadRecaptcha = function(){
    if(recaptchaLoaded) {
        return;
    }
    recaptchaLoaded = true;

    var re = document.createElement('script');
    re.type = 'text/javascript';
    re.src = 'https://www.google.com/recaptcha/api.js?render=' + recaptchaSiteKey;

    re.addEventListener('load', function(){
        $('.js-recaptcha').each(function(i, form){
            form.addEventListener('submit', function(event){
                var tokenInput = form.querySelector('input[name="recaptcha_token"]');
                event.preventDefault();
                window.grecaptcha.ready(function() {
                    window.grecaptcha.execute(recaptchaSiteKey, {action: 'submit'}).then(function(token) {
                        tokenInput.value = token;
                        form.submit();
                    });
                });
            });
        });
    });

    document.body.appendChild(re);
}

if(recaptchaSiteKey) {
    $('.js-recaptcha').find(':input').one('focus', loadRecaptcha);
}
