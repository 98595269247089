import * as Sentry from "@sentry/browser";
import {pageInfo} from "./page_info.js";

(function(){
    var dsn = pageInfo.sentry_dsn;
    if (!dsn || window.navigator.userAgent.indexOf('FB_IAB') !== -1) {
        return;
    }
    Sentry.init({
        dsn: dsn,
        whitelistUrls: [window.location.hostname],
        ignoreErrors: ["Non-Error promise", "NOT_SUPPORTED_ERR"]
    });
    Sentry.setTag('logger', 'javascript');
})();
