"use strict";

$(document).on('click', '[data-loading-text]', function(){
    var $button_or_input = $(this),
        $form = $(this).parents("form");
    if (!$form || $(":invalid", $form).length == 0) {
        var d = 'disabled',
            text = $button_or_input.data('loading-text'),
            val = $button_or_input.is('input') ? 'val' : 'html';

        // push to event loop to allow forms to submit
        setTimeout(() => {
          $button_or_input[val](text);
          $button_or_input.addClass(d).attr(d, d).prop(d, true);
        }, 0);
    }
});
