$(function() {
    let numOfSecondsLeft = $(".timer").data("numOfSecondsLeft");

    // Temporarily holds the remainder seconds as the "convert" function
    // calculates the days, hours, minutes, and seconds from the number of
    // seconds left; and then resets it to the new number of seconds left, after
    // the countdown reduces by 1 second
    let tempSeconds = numOfSecondsLeft;

    // Calculates the number of days, hours, minutes or seconds (depending on
    // what is passed as the second argument) from a given number of seconds
    const convert = function(value, numOfSecondsInUnit) {
        if (value > numOfSecondsInUnit) {
            let remainder = value % numOfSecondsInUnit;
            tempSeconds = remainder;
            return (value - remainder) / numOfSecondsInUnit;
        } else {
            return 0;
        }
    };

    // Update the countdown every 1 second
    const intervalID = setInterval(function() {
        if (numOfSecondsLeft <= 0) {
            // Stop the countdown
            clearInterval(intervalID);
        }
        $("#days .value").html(convert(tempSeconds, 24 * 60 * 60));
        $("#hours .value").html(convert(tempSeconds, 60 * 60));
        $("#minutes .value").html(convert(tempSeconds, 60));
        $("#seconds .value").html(convert(tempSeconds, 1));
        numOfSecondsLeft--;
        tempSeconds = numOfSecondsLeft;
    }, 1000);
});
