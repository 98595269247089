"use strict";

import oscar from "../oscar_ui.js";

export default function copyToClipboard(content) {
    navigator.clipboard.writeText(content).then(() => {
        oscar.messages.success('Successfully copied link!');
    });
}

$('.js-go-back').click(function(e){
    e.preventDefault();
    window.history.go(-1);
});

$('.js-currency-picker-select').on('change', function() {
    $(this).closest('form').submit();
});

$('.js-copy-text').on('click', function () {
    copyToClipboard($(this).data('content-to-copy'));
});
