"use strict";
import {pageInfo} from "./page_info.js";

window.dataLayer = window.dataLayer || [];
window.gtag = function(){dataLayer.push(arguments)};
gtag('js', new Date());

var analyticsData = pageInfo.google_analytics_data;
if(analyticsData){
    var gs = document.createElement('script');
    gs.type = 'text/javascript';
    gs.async = true;
    gs.src = 'https://www.googletagmanager.com/gtag/js?id=' + analyticsData.id;
    document.body.appendChild(gs)

    var params = {'transport_type': 'beacon'};
    if(analyticsData.user_id) {
        params['user_id'] = analyticsData.user_id;
    }
    gtag('config', analyticsData.id, params);
}

$(function() {
    if(!window.gtag) {
        return;
    }

    var eventsEl = document.getElementById('ga-events');
    if (eventsEl !== null) {
        var events = null;
        try {
            events = JSON.parse(eventsEl.textContent);
        } catch (e) {}
        if (events !== null) {
            $.each(events, function(i, event) {
                gtag('event', event['name'], event['data']);
            });
        }
    }

    $(document).on('basket_add_form_submitted compact_basket_line_removed basket_formset_changed', function(e, data) {
        $.each(data.ga_events, function(i, event) {
            gtag('event', event['name'], event['data']);
        });
    });
});
