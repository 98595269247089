"use strict";

$(function() {
    const $container = $('.voucher-form'),
          $voucherFormContainer = $container.find('#voucher_form_container'),
          $voucherFormLink = $container.find('#voucher_form_link'),
          $codeInput = $container.find('#id_code');

    function showVoucherForm() {
        $voucherFormContainer.show();
        $voucherFormLink.hide();
        $codeInput.focus();
    }

    function hideVoucherForm() {
        $voucherFormContainer.hide();
        $voucherFormLink.show();
    }

    $container.on('click', '#voucher_form_link a', function(event) {
        showVoucherForm();
        event.preventDefault();
    });

    $container.on('click', '#voucher_form_cancel', function(event) {
        hideVoucherForm();
        event.preventDefault();
    });

    if (window.location.hash == '#voucher') {
        showVoucherForm();
    }
});
