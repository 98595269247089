"use strict";

import "regulus-oscar";
import imagesLoaded from 'imagesloaded';
import AOS from 'aos';

AOS.init();

// Hide top bar when menu is expanded
$('#navbar-menu').on('show.bs.collapse', function () {
    $('.header-top-bar, .top-bar-promo').slideUp();
    $('.content').hide();
});
$('#navbar-menu').on('hide.bs.collapse', function () {
    $('.header-top-bar, .top-bar-promo, .content').slideDown();
    $('.content').show();
});

// Disable the dropdown-toggle on category menu items on large screens.
$('.header-menu .nav-link').on('click', function(e) {
    if ($(window).width() >= 992) {
        e.stopPropagation();
    }
});


$(function(){
    // Muck about with height of header image on certain devices
    var searchHeight = $('.js-header-search').height(),
        logo = $('.header-brand img');

    if($(window).width() > 992 && logo.height() + 2 > searchHeight) {
        logo.css('max-height', searchHeight - 2);
        $('.header-brand').css('max-width', logo.width());
    }

    // Remove the js-toggler-animate class on the toggle button after it's been used once,
    // otherwise it keeps animating when you change its class.
    $('#navbar-menu').on('show.bs.collapse', function () {
        $('.js-toggler-animate').removeClass('js-toggler-animate');
    });
});


function toggleStickyElementVisibility(stickySelector, relativeToSelector) {
    var sticky = $(stickySelector),
        isVisible = sticky.is(':visible'),
        relativeTo = $(relativeToSelector);

    if (!isVisible || !sticky.length || !relativeTo.length) {
        return;
    }

    var fixedPos = $(relativeToSelector).offset().top;
    $(window).on('scroll', function(){
        if($(document).scrollTop() + $(window).height() > fixedPos) {
            sticky.addClass('d-none');
        }
        else {
            sticky.removeClass('d-none');
        }
    });
}

// Hide the sticky add-to-cart button on mobile, if the user has scrolled to the position of the fixed one
imagesLoaded('.catalogue-gallery', function(){
    toggleStickyElementVisibility('.add-to-cart-sticky', '.add_to_cart .btn')
});

// Hide the sticky proceed to checkout button on mobile, if the user has scrolled to the position of the fixed one
$(function(){
    toggleStickyElementVisibility('.basket-header-checkout', '.basket .btn-primary[href="/checkout/"]')
});

function maybeDisableNavDropDown() {
    var dropdowns = $('.header-menu .dropdown-toggle');
    if ($(window).width() < 992) {
        dropdowns.removeAttr('data-toggle');
    }
    else {
        dropdowns.attr('data-toggle', 'dropdown');
    }
}

$(window).on('load resize', maybeDisableNavDropDown);


// Horizontal scrolling for product lists
$(".js-scroll").click(function(e){
    e.preventDefault();
    var $list = $(this).closest('.product-list-recommended').find('.product-list'),
        list = $list[0],
        itemWidth = parseInt($list.find(".product-card").width()),
        step = $(this).hasClass('js-scroll-left') ? -itemWidth : itemWidth;
    list.scroll({
        left: list.scrollLeft + step * 2,
        behavior: "smooth"
    });
});
